<template>
  <div class="page">
    <div class="m-t-10 responsive-box c-gap-20 r-gap-10" style="line-height:22px;">
      <div class="bold responsive-box-child">{{ $t('legion.ranks.title') }}</div>
      <div class="responsive-box-child" style="color:#E6A23C;">{{ $t('legion.ranks.subtitle') }}</div>
    </div>
    <div class="flex-1" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.5)">
      <div class="m-t-20">
        <table class="black-table bold" v-if="!!rankList" style="line-height:22px;">
          <tr>
            <td>{{ $t('legion.ranks.headerRanking') }}</td>
            <td>{{ $t('legion.ranks.headerAccount') }}</td>
            <td>{{ $t('legion.ranks.headerIntegral') }}</td>
          </tr>
          <tr v-for="item in rankList" :key="item.address">
            <td>{{ item.rank }}</td>
            <td>{{ item.address | formatAddress }} <span class="m-l-5" style="color:#14E0B6;">{{ (infos.address == item.address ? '(' + $t('legion.ranks.myOwn') + ')' : '') }}</span></td>
            <td>{{ Number(item.integral).toFixed(2) }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      rankList: [],
    };
  },
  computed: {
    ...mapState({
      infos: (state) => state.infos,
      sets: (state) => state.sets,
    }),
  },
  mounted() {
    this.getInfos();
    setTimeout(() => {
      this.pageFun();
    }, 200);
  },
  methods: {
    getInfos () {
      this.$store.dispatch("Get_infos").then((res) => {
        this.email = res.email;
      });
    },
    pageFun() {
      this.loading = true;
      this.$axios
        .get(
          this.$api.integralRanks,
          null,
          { Serverid: this.$store.state.serverid }
        )
        .then((res) => {
          this.rankList = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>


<style lang="scss" scoped>

table.black-table {
  width: 100%;
  background: rgba(41, 43, 57, 0.5);
  border: 1px solid #4c5158;
  border-radius: 4px;

  td {
    padding: 14px;
    border: 1px solid #4c5158;
    height: 86px;
    vertical-align: middle;
    text-align: center;
    font-size: 16px;
    width: 33%;
  }
}

.responsive-box {
  display: flex;
}

@media screen and (max-width: 768px) {
  .responsive-box {
    display: flex;
    flex-direction: column;
  }

}
</style>